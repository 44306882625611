import React from 'react'
import PropTypes from 'prop-types'
import { Grid } from '@material-ui/core'
import Section from 'src/components/Section'

const TwoCol = ({ background, left, right, children }) => (
  <Section background={background} hasPaddingLarge>
    <Grid container spacing={8}>
      <Grid item xs={12} lg={6}>
        {left}
      </Grid>
      <Grid item xs={12} lg={6}>
        {right}
      </Grid>
    </Grid>
    {children}
  </Section>
)

TwoCol.propTypes = {
  background: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  left: PropTypes.node.isRequired,
  right: PropTypes.node.isRequired,
  children: PropTypes.node,
}

TwoCol.defaultProps = {
  background: undefined,
  children: null,
}

export default TwoCol
