import React from 'react'
import PropTypes from 'prop-types'
import Heading2Line from 'src/components/Heading2Line'
import Wysiwyg from 'src/components/Wysiwyg'
import ImageWithBackground from 'src/components/ImageWithBackground'
import SectionPattern from 'src/components/SectionPattern'
import HeadingWysiwyg from 'src/sections/HeadingWysiwyg'
import TwoCol from 'src/sections/TwoCol'
// import Testimonials from 'src/sections/Testimonials'

import { getThemeVar } from 'src/styles/mixins'

const ServicesTemplate = ({ acf }) => {
  const intro = acf.servicesIntro
  const cm = acf.servicesContractManufacturing
  // const testimonials = acf.servicesTestimonials
  const ca = acf.servicesContractAnalytics

  return (
    <>
      <HeadingWysiwyg
        background={getThemeVar('color.lightblue')}
        preHeading={intro.preHeading}
        heading={intro.heading}
        textWysiwyg={intro.textWysiwyg}
      >
        <SectionPattern positionX="right" />
      </HeadingWysiwyg>

      <TwoCol
        left={(
          <ImageWithBackground
            imgFluid={cm.image.localFile.childImageSharp.fluid}
            // alt={cm.image.altText}
          />
        )}
        right={(
          <>
            <Heading2Line preHeading={cm.preHeading} heading={cm.heading} />
            <Wysiwyg>{cm.textWysiwyg}</Wysiwyg>
          </>
        )}
      >
        <SectionPattern />
      </TwoCol>

      {/* Hidden Testimonials Section
      <Testimonials
        background={getThemeVar('color.lightblue')}
        preHeading={testimonials.preHeading}
        heading={testimonials.heading}
        testimonials={testimonials.testimonials}
      >
        <SectionPattern positionX="right" />
      </Testimonials>
      */}

      <HeadingWysiwyg
        background={getThemeVar('color.lightblue')}
        preHeading={ca.preHeading}
        heading={ca.heading}
        textWysiwyg={ca.textWysiwyg}
        beforeFooter
      />
    </>
  )
}

ServicesTemplate.propTypes = {
  acf: PropTypes.shape({
    servicesIntro: PropTypes.shape({
      preHeading: PropTypes.string,
      heading: PropTypes.string,
      textWysiwyg: PropTypes.string,
    }),

    servicesContractManufacturing: PropTypes.shape({
      image: PropTypes.object.isRequired, // eslint-disable-line
      preHeading: PropTypes.string,
      heading: PropTypes.string,
      textWysiwyg: PropTypes.string,
    }),

    servicesTestimonials: PropTypes.shape({
      preHeading: PropTypes.string,
      heading: PropTypes.string,
      testimonials: PropTypes.arrayOf(
        PropTypes.shape({
          portrait: PropTypes.object.isRequired, // eslint-disable-line
          name: PropTypes.string,
          title: PropTypes.string,
          textWysiwyg: PropTypes.string,
        })
      ).isRequired,
    }),

    servicesContractAnalytics: PropTypes.shape({
      preHeading: PropTypes.string,
      heading: PropTypes.string,
      textWysiwyg: PropTypes.string,
    }),
  }).isRequired,
}

export default ServicesTemplate
